import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary">
        {'Copyright © '}
        <Link color="inherit" href="https://mupira.com/">
          247fandom
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
   h1: {
      fontWeight: "900",
      fontSize: "4rem"
   }
}));

export default function App() {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <CssBaseline />

        <Container component="main" className={classes.main} maxWidth="sm">
          <Typography variant="h2" component="h1" gutterBottom classes={
              {
                  root: classes.h1
              }
          }>
            247fandom
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            Mupira will be rebranded to 247fandom. Thank you to all that joined our platform.
            We intended for Mupira to be a sport social networking app with personal connections.
            However, we listened to our users and understood that they wanted something more engaging. We
            are building 247fandom. 247fandom will be built around thriving communities that allow people to engage on everything sports.
            Sport fans can find communities they are interested in, join them and engage with other fans around the world.
            Stay tuned as we launch soon.
          </Typography>

          <Typography variant="h6" component="h2"> 247fandom team</Typography>
        </Container>


        <footer className={classes.footer}>
          <Container maxWidth="sm">
            <Copyright />
          </Container>
        </footer>

      </div>
  );
}
